
/***
 * GERAL
 ***/
$(function() {
    $('.animsition').animsition().one('animsition.inStart',function(){
        App.SetToggle();
        App.SetGaleria();
        App.SetBanner();
        App.SetCarouselMobile();
        App.SetHeaderFixed(130);
        App.SetValidate();
        App.SetMaskInput();
        App.SetInputError();
        App.SetFormSuccess();
        App.SetAnimatescroll(150);
        App.SetWow(200);

        $('html').addClass('loaded');

        // Tabs
        $(document).on('click', '.lista .item', function(event) {
            event.preventDefault();
            
            $(this).toggleClass('_active');
        });

        var url = window.location.href;
        var id  = url.substring(url.lastIndexOf('/') + 1);

        if (id) {
            $('.lista').find(id).toggleClass('_active');
        }

        // Cookies
        if (localStorage.hasOwnProperty('verify_cookies')) {
            $('.Cookies').addClass('_hide');
        }

        $('.Cookies .Button').click(function(event) {
            event.preventDefault();
            localStorage.setItem('verify_cookies', true);
            $('.Cookies').addClass('_hide');
        });

        // Select categorias mobile
        $(document).on('change', '.select-categorias', function(event) {
            if ($(this).val()) {
                window.location.href = site_url + '/categorias/' + $(this).val();
            }
        });
    });
});


